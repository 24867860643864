<template>
  <div>
    <h3>Error: Not Found</h3>
    <p>
      大会情報が見つかりませんでした。<br>
      URLに誤りがある、または大会が削除された可能性があります。
    </p>
    <p>
      <router-link :to="`/${lang}/`">トップに戻る</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  computed: {
    lang() {
      return this.$route.params.lang
    },
  },
}
</script>

<style>

</style>